/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Flex } from 'theme-ui';
import Facebook from './icons/facebook';
import Instagram from './icons/Instagram';
import Twitter from './icons/Twitter';
import Fork from './icons/Fork';
import Guy from './icons/GoNationMan';

// const SocialRow = styled.div`
//   display: flex;
//   a {
//     margin-left: 3rem;
//   }
// `;

const SocialIcons = ({
  iconWidth = '30px',
  iconFill = '#fff',
  links,
  slug,
  justifyLeft,
  iconMargin = '0',
}) => {
  const getSocialIcon = social => {
    switch (social) {
      case 'facebook':
        return <Facebook fill={iconFill} width={iconWidth} />;
      case 'instagram':
        return <Instagram fill={iconFill} width={iconWidth} />;
      case 'twitter':
        return <Twitter fill={iconFill} width={iconWidth} />;
      case 'Order Online':
        return <Fork fill={iconFill} width={iconWidth} />;
      default:
        return '';
    }
  };

  const renderSocialIcons = () => (
    <>
      {Object.keys(links)
        .filter(
          el =>
            el === 'facebook' ||
            el === 'instagram' ||
            el === 'twitter' ||
            el === 'Order Online' ||
            el === 'Reservations'
        )
        .map(
          (social, idx) =>
            links[social] && (
              <a
                style={idx === 0 ? { margin: 0 } : {}}
                style={{ marginRight: iconMargin, display: 'inline-block' }}
                target="_blank"
                rel="noopener noreferrer"
                href={links[social]}
              >
                {getSocialIcon(social) ? getSocialIcon(social) : social}
              </a>
            )
        )}
      <a
        sx={{ marginRight: iconMargin, display: 'inline-block' }}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://gonation.com/place/${slug}/pulse`}
      >
        <Guy color={iconFill} height={iconWidth} width={iconWidth} />
      </a>
    </>
  );

  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: justifyLeft ? 'flex-start' : 'space-evenly',
      }}
    >
      {renderSocialIcons()}
    </Flex>
  );
};

export default SocialIcons;
